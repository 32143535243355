<template>
  <span class="svg-icon flex-shrink-0" :class="[custom_class, size_class_mappings[size]]" />
</template>

<script>
export default {
  mounted() {
    this.load_svg()
  },

  watch: {
    icon() {
      this.load_svg()
    },
  },

  props: {
    icon: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      required: false,
    },

    override_color: {
      type: Boolean,
      default: true,
    },

    custom_class: {
      type: String,
      default: '',
    },
  },

  data: () => {
    return {
      size_class_mappings: {
        sm: 'svg-icon-sm',
        md: 'svg-icon-md',
        lg: 'svg-icon-lg',
        xl: 'svg-icon-xl',
        '2xl': 'svg-icon-2xl',
        '3xl': 'svg-icon-3xl',
        '4xl': 'svg-icon-4xl',
      },
    }
  },

  methods: {
    async load_svg() {
      if (!this.$el) return
      try {
        const { default: svg } = await import(`../../../images/icons/${this.icon}.svg?raw`)
        this.$el.innerHTML = svg
        this.clean_svg(this.$el.firstElementChild)
      } catch (e) {
        console.error(`VL: Could not find svg icons/${this.icon}.svg`, e)
      }
    },

    clean_svg(svg_el) {
      if (!svg_el) return
      svg_el.setAttribute('id', this.icon)
      svg_el.removeAttribute('height')
      svg_el.removeAttribute('width')
      svg_el.removeAttribute('fill')

      if (this.override_color) {
        svg_el.querySelectorAll('*').forEach(el => {
          if (el && el.getAttribute('fill') !== 'none') el.removeAttribute('fill')
        })
      }
    },
  },
}
</script>
